var big_image;

function removeElement(el)
{
    el.fadeOut(function() {
        el.remove();
    });
}

function getWordCount(wordString) {
    var words = wordString.split(" ");
    words = words.filter(function(words) {
        return words.length > 0
    }).length;
    return words;
}

function changeTotalPrice(e, price)
{
    e.fadeOut(function() {
        $(this).text(price);
        $(this).fadeIn();
    });
}

$().ready(function(){
    responsive = $(window).width();

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    jQuery.validator.addMethod("wordCount",
        function(value, element, params) {
            var count = getWordCount(value);
            if(count >= params[0]) {
                return true;
            }
        },
        jQuery.validator.format("A minimum of {0} words is required here.")
    );

    $('.validate').validate();

    $( "#checkoutForm" ).validate({
        rules: {
            delivery_id: {
                required: true
            },
            terms: {
                required: true
            }
        }
    });

    $('.add-to-cart').on('click',function(){
        //Scroll to top if cart icon is hidden on top
        $('html, body').animate({
            'scrollTop' : $(".cart_anchor").position().top
        });
        //Select item image and pass to the function
        var itemImg = $(this).parent().parent().find('img').eq(0);
        flyToElement($(itemImg), $('.cart_anchor'));
    });

    var $cardContent = $('#cardContent');
    var $totalPrice = $('.cart-total-value');

    $cardContent.find('.close').click(function() {
        var $Item = $(this).parent().parent();
        var $productId = $Item.data('id');
        var $allProductItemElements = $('.product-item_'+$productId);
        var data = {
            id: $productId
        };

        $.post('/cart/remove', data, function(response) {
            removeElement($allProductItemElements);

            changeTotalPrice($totalPrice, response.cart.totalPrice);
        });
    });

    $cardContent.find('.cart-increment').click(function() {
        var $Item = $(this).parent().parent().parent();
        var $productId = $Item.data('id');
        var $allProductItemElements = $('.product-item_'+$productId);
        var currentQty = $Item.find('.qty-value').text();
        var itemPrice = $Item.find('.price-value').text();
        var $itemSubtotal = $Item.find('.subtotal-value');

        var data = {
            id: $productId
        };
        $.post('/cart/increment', data, function(response) {
            currentQty++;
            $itemSubtotal.text(currentQty*itemPrice);
            $allProductItemElements.find('.qty-value').text(currentQty);
            changeTotalPrice($totalPrice, response.cart.totalPrice);
        });
    });

    $cardContent.find('.cart-decrement').click(function() {
        var $Item = $(this).parent().parent().parent();
        var $productId = $Item.data('id');
        var $allProductItemElements = $('.product-item_'+$productId);
        var currentQty = $Item.find('.qty-value').text();
        var itemPrice = $Item.find('.price-value').text();
        var $itemSubtotal = $Item.find('.subtotal-value');

        var data = {
            id: $productId
        };
        $.post('/cart/decrement', data, function(response) {
            currentQty--;

            if(currentQty == 0) {
                removeElement($Item)
            }

            $itemSubtotal.text(currentQty*itemPrice);
            $allProductItemElements.find('.qty-value').text(currentQty);
            changeTotalPrice($totalPrice, response.cart.totalPrice);
        });
    });

    var $checkoutContent = $('#checkoutContent');

    $('input[name=delivery_id]', $checkoutContent).parent().click(function() {
        var deliveryPrice = parseFloat($('input', $(this)).data('deliveryPrice'));
        var totalPrice = parseFloat($('.total-price', $checkoutContent).data('totalPrice'));

        changeTotalPrice($('.shipping-price', $checkoutContent), deliveryPrice);

        changeTotalPrice($('.total-price', $checkoutContent), totalPrice + deliveryPrice);
    });

    $(window).on('scroll', gsdk.checkScrollForTransparentNavbar);

    if (responsive >= 768){
        big_image = $('.parallax-image').find('img');

        $(window).on('scroll',function(){
            parallax();
        });
    }

    $('[rel="tooltip"]').tooltip();

    $("a[href^='#']").click(function() {
        var $target = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $($target).offset().top
        }, 800);
        return false;
    });

    var $calendarObject = $('#eventCalendarPublic');
    var calendarUrl = $calendarObject.data('url');
    $calendarObject.fullCalendar({
        eventSources: [
            {
                url: calendarUrl,
                type: 'GET',
                error: function() {
                    alert('there was an error while fetching events!!');
                },
                textColor: '#ffffff',
                color: '#00c0ef',
                backgroundColor: '#00c0ef',
                editable: false,
            }
        ],
        views: {
            month: {
                timeFormat: 'H:mm'
            },
            agendaWeek: {
                slotLabelFormat: 'H:mm'
            },
            agendaDay: {
                slotLabelFormat: 'H:mm'
            }
        },
        editable: true,
        lang: 'sk',
        scrollTime: '12:00',
        //businessHours: {
        //    start: '9:00',
        //    end: '21:00'
        //},
        header:{
            left: 'title',
            center: 'month,agendaWeek',
            right: 'prev,today,next'
        },
        eventRender: function (event, element) {
            var startTime = moment(new Date(event.start_at)).format('D.MMM YYYY o H:mm');

            element.popover({
                html: true,
                placement: 'top',
                title: event.title,
                content: '<p>' + startTime + '<br>' + event.place + '</p>',
            });
        },
        dayClick: function() {},
        eventClick: function(event, jsEvent, view) {
            $(this).popover('show');
        },
        eventDrop: function() {},
        eventResize: function() {},
        eventMouseover: function(event, jsEvent, view) {
            $(this).popover('show');
        },
        eventMouseout: function(event, jsEvent, view) {
            $(this).popover('hide');
        },
    });

    var $selectCategory = $('#selectCategory');
    var $productContainer = $('#productContainer');

    $('input[name=category]', $selectCategory).change(function() {
        var $this = $(this);
        var categoryId = parseInt($this.val());
        var isChecked = $this.prop('checked');

        $('.productItem', $productContainer).each(function() {
            var $this = $(this);
            var categories = $this.data('categories');

            if(categories.indexOf(categoryId) != -1) {
                if(isChecked) {
                    $this.fadeIn();
                } else {

                    $this.fadeOut();
                }
            } else {
            }
        });

    });

});

var parallax = function() {
    var current_scroll = $(this).scrollTop();

    oVal = ($(window).scrollTop() / 3);
    big_image.css('top',oVal);
};